import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Image, Text, Button } from "@chakra-ui/react";
import { format } from "date-fns";
import eventImg1 from "../Assets/Images/event-img1.svg";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/layout/Header";
import { IoIosArrowRoundBack } from "react-icons/io";
import { seteventDetails } from "../redux/slices/eventRegistrationSlice";
import { fetchAllEvents } from "../redux/slices/eventRegistrationSlice";
import MobileHeader from "../components/layout/MobileHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const EventDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchAllEvents());
  }, [dispatch]);

  const allEvents = useSelector((state) => state.eventRegistration?.allEvents);
  const eventData = allEvents?.filter(
    (event) => parseInt(event.id, 10) === parseInt(id, 10)
  );
  // console.log(eventData[0].eventType);

  if (eventData?.length <= 0) {
    return (
      <Box
        textAlign={"center"}
        margin={"50px"}
        onClick={() => navigate("/all-events")}
      >
        Go to Events
      </Box>
    );
  }

  const handleRegister = async (eventData) => {
    await dispatch(seteventDetails(eventData));
    dispatch(setFormModal({ status: true, type: "eventRegCont" }));
  };
  const handleGetTag = () => {
    dispatch(
      setFormModal({ status: true, type: "getTag", data: eventData[0]?.id })
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };

  return (
    <>
      <Header logo={"true"} />
      <MobileHeader show={false} logo={"true"} />
      <Flex
        minH={"100vh"}
        gap={3}
        flexDirection={["column", "column", "row"]}
        bg={"#F6FFF9"}
        p={4}
      >
        {eventData?.map((event) => (
          <>
            <Box
              bg={"#FFFFFF"}
              w={["100%", "100%", "65%"]}
              p={"20px"}
              key={event?.id}
            >
              <IoIosArrowRoundBack
                style={{
                  cursor: "pointer",
                  margin: "20px 0  20px 0",
                  width: "30px",
                  height: "30px",
                }}
                onClick={() => navigate(-1)}
              />
              <Box overflow={"hidden"} borderRadius={"16px"} width={"full"}>
                <Image
                  src={event?.coverImage ? event?.coverImage : eventImg1}
                  alt="Event Image"
                  h={["300px", "400px", "500px"]}
                  width={"full"}
                  overflow={"hidden"}
                  borderRadius={"16px"}
                  objectFit={"cover"}
                />
              </Box>
              <Text
                color={"#1E1E1E"}
                fontSize={"24px"}
                fontWeight={"600"}
                py={5}
              >
                {event?.name}
              </Text>
              <Text color={"#121212"} fontSize={"20px"} fontWeight={"500"}>
                About Event
              </Text>
              <Text
                py={3}
                color={"#54595F"}
                fontSize={"16px"}
                fontWeight={"400"}
                lineHeight={"19.2px"}
                letterSpacing={"1px"}
              >
                {event?.information}
              </Text>

              {eventData[0].eventType !== "Awards" ? (
                <>
                  <Text color={"#121212"} fontSize={"20px"} fontWeight={"500"}>
                    Registration Includes:
                  </Text>
                  <Box
                    color={"#54595F"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    lineHeight={"19.2px"}
                  >
                    <Text pt={2}>
                      Access to all keynote sessions, workshops and panel
                      discussions.
                    </Text>
                    <Text pt={1}>
                      Networking opportunities with industry leaders.
                    </Text>
                    <Text pt={1}>
                      Entry to the Tech Expo and Startup Showcase.
                    </Text>
                    <Text pt={1}>
                      Conference materials and exclusive TechConnect swag.
                    </Text>
                  </Box>
                </>
              ) : null}
            </Box>

            <Box bg={"#FFFFFF"} w={["100%", "100%", "35%"]} p={"20px"}>
              <Text color={"#54595F"} fontSize={"20px"} fontWeight={"500"}>
                Start & End Date
              </Text>
              <Text color={"#121212"} fontSize={"16px"} fontWeight={"400"}>
                {format(new Date(event?.startDate), "EEEE do MMM yyyy")} -{" "}
                {format(new Date(event?.endDate), "EEEE do MMM yyyy")}
              </Text>

              <Text
                color={"#54595F"}
                fontSize={"20px"}
                fontWeight={"500"}
                pt={5}
              >
                Event Type
              </Text>
              <Text color={"#121212"} fontSize={"16px"} fontWeight={"400"}>
                {event?.eventType}
              </Text>

              <Text
                color={"#54595F"}
                fontSize={"20px"}
                fontWeight={"500"}
                pt={5}
              >
                Location
              </Text>
              <Text color={"#121212"} fontSize={"16px"} fontWeight={"400"}>
                {event?.location}
              </Text>

              <Text
                color={"#54595F"}
                fontSize={"20px"}
                fontWeight={"500"}
                pt={5}
              >
                Guest Speaker(s)
              </Text>

              <Slider {...settings}>
                {event?.guestSpeakers?.map((speaker) => (
                  <Flex
                    flexDirection={"column"}
                    justifyContent={"center"}
                    gap={5}
                    alignItems={"center"}
                    mt={5}
                  >
                    <Image
                      src={speaker?.imageUrl}
                      alt="guest speaker image"
                      h={"150px"}
                      w={"150px"}
                      borderRadius={"10px"}
                    />
                    <Text
                      color={"#121212"}
                      fontSize={"16px"}
                      fontWeight={"700"}
                      pt={2}
                    >
                      {speaker?.firstName + " " + speaker?.lastName}
                    </Text>
                    <Text
                      color={"#54595F"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      Topic: {speaker?.topic}
                    </Text>
                    <Text
                      color={"#54595F"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      Biography: {speaker?.biography}
                    </Text>
                  </Flex>
                ))}
              </Slider>

              <Button
                mt={12}
                width={"100%"}
                background={"#22944E"}
                color={"#FFFFFF"}
                fontSize={"16px"}
                fontWeight={"700"}
                _hover={{ background: " " }}
                onClick={() => handleRegister(eventData)}
              >
                Register
              </Button>
              <Text my={5}>
                Already Registered?{" "}
                <span
                  style={{
                    color: "#22944E",
                    cursor: "pointer",
                    textDecoration: "underline",
                    paddingLeft: "5px",
                  }}
                  onClick={handleGetTag}
                >
                  Generate Tag
                </span>
              </Text>
            </Box>
          </>
        ))}
      </Flex>
    </>
  );
};

export default EventDetails;
