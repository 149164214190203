import React, { useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal, setFormModal } from "../redux/slices/modalSlice";
import {
  fetchMembershipType,
  setMembershipTypeDetails,
  fetchEvents,
  setEditType,
} from "../redux/slices/membershipTypeSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";

const MembershipTypeTable = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMembershipType());
    dispatch(fetchEvents());
    dispatch(clearsearchFilter());
  }, [dispatch]);

  const membershipType = useSelector(
    (state) => state?.membershipType?.membershipType
  );
  // console.log(membershipType);

  const handleView = async (item) => {
    dispatch(
      setFormModal({
        status: true,
        title: "Membership Type Details",
        type: "viewMembershipType",
      })
    );
    await dispatch(setMembershipTypeDetails(item));
  };

  const handleEdit = async (item) => {
    dispatch(
      setFormModal({
        status: true,
        title: "Edit Membership Type",
        type: "editMembershipType",
      })
    );
    await dispatch(setEditType(item));
  };

  const handleDeactivate = async (item) => {
    dispatch(
      setConfirmModal({
        status: true,
        type: "deactivateMembershipType",
        title: item.isActive ? "Deactivate " : "Activate",
        desc: item.isActive
          ? `Are you sure you want to deactivate MembershipType ${item.name}?`
          : `Are you sure you want to activate ${item.name}?`,
        buttonText: item.isActive
          ? `Yes, Deactivate ${item.name}`
          : `Yes, Activate ${item.name}`,
        payload: item,
      })
    );
  };

  const handleDelete = async (item) => {
    // console.log("Delete item",item);

    try {
      await httpRequest(`${BASE_URL}/MembershipType/delete/${item.id}`, "post");

      await dispatch(fetchMembershipType());
    } catch (error) {
      console.log(error);
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const tableProps = {
    data: membershipType,
    title: {
      membershipTypeName: "Membership Type Name",
      earlyBirdAmount: "Early Bird",
      midBirdAmount: "Mid Bird",
      lateBirdAmount: "Late Bird",
      attendanceType: "Attendance Type",
      status: "Status",
      action: "Action",
    },
    dataKeys: [
      "membershipTypeName",
      "earlyBirdAmount",
      "midBirdAmount",
      "lateBirdAmount",
      "attendanceType",
      "status",
      "action",
    ],
    variant: true,
    sn: true,
    transformRow: (item) => ({
      membershipTypeName: (
        <div style={{ cursor: "pointer" }} onClick={() => handleView(item)}>
          {item?.name}
        </div>
      ),

      earlyBirdAmount: (
        <div style={{ cursor: "pointer" }} onClick={() => handleView(item)}>
          {formatAmount(item?.earlyBirdAmount)}
        </div>
      ),
      midBirdAmount: (
        <div style={{ cursor: "pointer" }} onClick={() => handleView(item)}>
          {formatAmount(item?.midBirdAmount)}
        </div>
      ),
      lateBirdAmount: (
        <div style={{ cursor: "pointer" }} onClick={() => handleView(item)}>
          {formatAmount(item?.lateBirdAmount)}
        </div>
      ),
      attendanceType: (
        <div style={{ cursor: "pointer" }} onClick={() => handleView(item)}>
          {item?.attendanceType}
        </div>
      ),
      status: (
        <Button
          color={"#356EFF"}
          bg={""}
          borderRadius={"4px"}
          fontSize={"16px"}
          lineHeight={"20px"}
          _hover={{ backgroundColor: "" }}
          onClick={() => handleView(item)}
        >
          {item?.isActive ? "Active " : "Deactivated"}
        </Button>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDeactivate(item)}>
              {item?.isActive ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };
  return (
    <Box my={4}>
      <CustomTable {...tableProps} sn={true} />
    </Box>
  );
};

export default MembershipTypeTable;
