import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { addMembershipTypeFormSchema } from "../utils/formValidationSchema";
import {
  createMembershipType,
  fetchMembershipType,
} from "../redux/slices/membershipTypeSlice";

const AddMembershipType = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPaidEvent, setIsPaidEvent] = useState(false);
  const events = useSelector((state) => state.membershipType?.events);
  const initialValues = {
    membershipTypeName: "",
    earlyBirdAmount: "",
    midBirdAmount: "",
    lateBirdAmount: "",
    attendanceType: "",
    accessToEvents: "",
  };

  const handleEventChange = (e, handleChange, setFieldValue) => {
    const selectedEventId = Number(e.target.value);
    if (selectedEventId) {
      const selectedEvent = events.find(
        (event) => event.id === selectedEventId
      );
      setIsPaidEvent(selectedEvent.isPaid);
      // console.log("Selected Event:", selectedEvent);
      if (!selectedEvent.isPaid) {
        setFieldValue("earlyBirdAmount", 0);
        setFieldValue("midBirdAmount", 0);
        setFieldValue("lateBirdAmount", 0);
      }
    } else {
      setIsPaidEvent(false);
      // Optionally reset amounts if no event is selected
      setFieldValue("earlyBirdAmount", "");
      setFieldValue("midBirdAmount", "");
      setFieldValue("lateBirdAmount", "");
    }
    handleChange(e);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        name: values?.membershipTypeName,
        earlyBirdAmount: values?.earlyBirdAmount,
        midBirdAmount: values?.midBirdAmount,
        lateBirdAmount: values?.lateBirdAmount,
        attendanceType: values?.attendanceType,
        eventId: values?.accessToEvents,
      };

      const response = await dispatch(createMembershipType(formData));
      if (!response?.payload.isSuccessful === true) {
        setLoading(false);
        setError(
          response?.payload.message || "Something went wrong. Please try again"
        );
        dispatch(fetchMembershipType());
        return;
      }
      setLoading(false);
      dispatch(setFormModal({ status: false }));
    } finally {
      // catch (error) {
      //   setError(error?.message || "Something went wrong. Please try again");
      // }
      setLoading(false);
      dispatch(setFormModal({ status: false }));
      dispatch(fetchMembershipType());
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addMembershipTypeFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <FormControl
              mt={4}
              isInvalid={
                errors.membershipTypeName && touched.membershipTypeName
              }
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel fontSize={"16px"}>Membership Type Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="membershipTypeName"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.membershipTypeName}
              />
              {touched.membershipTypeName && errors.membershipTypeName && (
                <FormErrorMessage>{errors.membershipTypeName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.accessToEvents && touched.accessToEvents}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel fontSize={"16px"}>Events Accessible</FormLabel>
              <Select
                name="accessToEvents"
                onBlur={handleBlur}
                onChange={(e) =>
                  handleEventChange(e, handleChange, setFieldValue)
                }
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
              >
                <option value={null}>Select Event</option>
                {events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))}
              </Select>
              {touched.accessToEvents && errors.accessToEvents && (
                <FormErrorMessage>{errors.accessToEvents}</FormErrorMessage>
              )}
            </FormControl>
            {isPaidEvent && (
              <>
                <FormControl
                  mt={4}
                  isInvalid={errors.earlyBirdAmount && touched.earlyBirdAmount}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel fontSize={"16px"}>Early Bird Amount</FormLabel>
                  <Input
                    placeholder="Enter Amount Here"
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="earlyBirdAmount"
                    focusBorderColor="#ffffff"
                    borderRadius={"0"}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.earlyBirdAmount}
                  />
                  {touched.earlyBirdAmount && errors.earlyBirdAmount && (
                    <FormErrorMessage>
                      {errors.earlyBirdAmount}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.midBirdAmount && touched.midBirdAmount}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel fontSize={"16px"}>Mid Bird Amount</FormLabel>
                  <Input
                    placeholder="Enter Amount Here"
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="midBirdAmount"
                    focusBorderColor="#ffffff"
                    borderRadius={"0"}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.midBirdAmount}
                  />
                  {touched.midBirdAmount && errors.midBirdAmount && (
                    <FormErrorMessage>{errors.midBirdAmount}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.lateBirdAmount && touched.lateBirdAmount}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel fontSize={"16px"}>Late Bird Amount</FormLabel>
                  <Input
                    placeholder="Enter Amount Here"
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="lateBirdAmount"
                    focusBorderColor="#ffffff"
                    borderRadius={"0"}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.lateBirdAmount}
                  />
                  {touched.lateBirdAmount && errors.lateBirdAmount && (
                    <FormErrorMessage>{errors.lateBirdAmount}</FormErrorMessage>
                  )}
                </FormControl>
              </>
            )}
            <FormControl
              mt={4}
              isInvalid={errors.attendanceType && touched.attendanceType}
              color="#121212"
              fontFamily={"inter"}
            >
              <FormLabel fontSize={"16px"}>Attendance Type</FormLabel>
              <Select
                name="attendanceType"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
              >
                <option value={null}>Select Event</option>
                {/* {events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.name}
                  </option>
                ))} */}
                <option key="physical" value="physical">
                  Physical
                </option>
                <option key="virtual" value="virtual">
                  Virtual
                </option>
                <option key="Author" value="Author">
                  Author
                </option>
              </Select>
              {touched.attendanceType && errors.attendanceType && (
                <FormErrorMessage>{errors.attendanceType}</FormErrorMessage>
              )}
            </FormControl>

            <Button
              mt={10}
              width={"100%"}
              background={"#22944E"}
              _hover={{ background: " " }}
              color={"#FFFFFF"}
              type="submit"
              isLoading={loading}
              loadingText="Saving ..."
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddMembershipType;
