import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import CreateAndFilter from "../components/layout/CreateAndFilter";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal, setConfirmModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setMemberDetails,
  setEditMember,
} from "../redux/slices/membershipMgtSlice";
import { fetchUploadedMembers } from "../redux/slices/membershipMgtSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";

const MembershipManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUploadedMembers());
    dispatch(clearsearchFilter());
  }, [dispatch]);

  const members = useSelector((state) => state.membershipMgt?.members);

  const createData = {
    createText: "Add New Member",
    showBulkUpload: true,
    onClick: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Add New Member",
          type: "addMember",
        })
      );
    },
    handleBulkUpload: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Upload Members",
          type: "uploadMembers",
        })
      );
    },
  };

  const handleUpdate = async (item) => {
    // console.log("item",item);
    dispatch(
      setFormModal({
        status: true,
        title: "Member Details",
        type: "memberDetails",
      })
    );
    await dispatch(setMemberDetails(item));
  };

  const handleEdit = async (item) => {
    // console.log("item",item);
    dispatch(
      setFormModal({ status: true, title: "Edit Member", type: "editMember" })
    );
    await dispatch(setEditMember(item));
  };

  const handleDeactivate = async (item) => {
    // console.log("item",item);
    dispatch(
      setConfirmModal({
        status: true,
        type: "deactivateMembership",
        title: item.isActive ? "Deactivate " : "Activate",
        desc: item.isActive
          ? `Are you sure you want to deactivate Membershipcode ${item.memberShipCode}?`
          : `Are you sure you want to activate ${item.memberShipCode}?`,
        buttonText: item.isActive
          ? `Yes, Deactivate ${item.memberShipCode}`
          : `Yes, Activate ${item.memberShipCode}`,
        payload: item,
      })
    );
  };

  const handleDelete = async (item) => {
    // console.log("Delete item",item);

    try {
      await httpRequest(
        `${BASE_URL}/MembershipManagement/delete-uploaded?memberId=${item.id}`,
        "post"
      );

      await dispatch(fetchUploadedMembers());
    } catch (error) {
      console.log(error);
    }
  };

  const tableProps = {
    data: members,
    title: {
      memberShipCode: "Membership Code",
      name: "Name",
      // email:"Email Address",
      memberShipType: "Membership Type",
      Status: "Status",
      Action: "Action",
    },
    dataKeys: [
      "memberShipCode",
      "name",
      // "email",
      "memberShipType",
      "Status",
      "Action",
    ],
    variant: "true",
    transformRow: (item) => ({
      memberShipCode: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.memberShipCode}
        </div>
      ),
      name: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.firstName + " " + item?.lastName}
        </div>
      ),
      // email: <div style={{cursor:'pointer'}}
      // onClick={() => handleUpdate(item)}
      // >{item?.email}</div>,
      memberShipType: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.membershipType}
        </div>
      ),
      Status: (
        <Button
          bg={""}
          borderRadius={"4px"}
          fontSize={"16px"}
          lineHeight={"20px"}
          _hover={{ backgroundColor: "" }}
          onClick={() => handleUpdate(item)}
          color={item?.isActive ? "#356EFF" : "#FFC34F"}
        >
          {item?.isActive ? "Active " : "Inactive"}
        </Button>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDeactivate(item)}>
              {item?.isActive ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <>
      <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"}>
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Membership Management
          </Text>
          <CreateAndFilter {...createData} />
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default MembershipManagement;
