import React, { useState } from "react";
import {
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { getTagSchema } from "../utils/formValidationSchema";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { setgetTagDetails } from "../redux/slices/eventSlice";
// import { setloadTost } from "../redux/slices/toastSlice";

const GetTag = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [retryPaymentUrl, setRetryPaymentUrl] = useState("");
  // const [success, setSuccess] = useState(false);
  // const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState("");
  const formModal = useSelector((state) => state.modal.formModal);
  const eventId = formModal?.data;

  const initialValues = {
    reference: "",
    eventId: 0,
  };

  const handleSubmit = async (values) => {
    setError("");
    setLoading(true);
    try {
      const formData = {
        Reference: values.reference,
        eventId: eventId,
      };
      const response = await httpRequest(
        `${BASE_URL}/Tag/generate-tag`,
        "post",
        formData
      );
      if (response?.isSuccessful === true) {
        setLoading(false);
        dispatch(setgetTagDetails(response?.data));
        dispatch(setFormModal({ status: false }));
        dispatch(setFormModal({ status: true, type: "getEventTag" }));
      } else if (
        response?.isSuccessful === false &&
        response?.tryPaymentAgain === true
      ) {
        setLoading(false);
        setRetryPaymentUrl(response?.data?.paystackRequest?.authorizationUrl);
        throw new Error(
          response?.successMessage || "Something went wrong. Try again soon"
        );
      } else {
        setLoading(false);
        throw new Error(
          response?.response?.data?.responseDescription ||
            "Something went wrong. Try again soon"
        );
      }
      // if (!response?.isSuccessful === true) {
      //   setLoading(false);
      //   setError(
      //     response?.response?.data ||
      //       "Failed to generate tag. Reference not found, Please try again"
      //   );
      //   return;
      // } else if (response?.data === null && response?.successMessage) {
      //   setLoading(false);

      //   setSuccess(true);
      //   setSuccessMsg(
      //     response?.successMessage || "Something went wrong. Try again soon"
      //   );
      //   // setError(
      //   //   response?.successMessage || "Something went wrong. Try again soon"
      //   // );
      //   // console.log(response?.successMessage);
      //   return;
      // } else if (response?.data === null) {
      //   setLoading(false);
      //   dispatch(
      //     setloadTost({
      //       title: "No Tag Yet",
      //       description:
      //         "Please Register for the event if not registered/ Try again later",
      //       status: "warning",
      //       duration: 3000,
      //       isClosable: true,
      //       position: "top",
      //     })
      //   );
      //   return;
      // } else {
      //   setLoading(false);
      //   console.log(response);
      //   // dispatch(setgetTagDetails(response?.data));
      //   // dispatch(setFormModal({ status: false }));
      //   // dispatch(setFormModal({ status: true, type: "getEventTag" }));
      // }
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRetryPayment = () => {
    dispatch(setFormModal({ status: false }));
    window.location.href = retryPaymentUrl;
  };

  return (
    <>
      {error && (
        <Alert status="error" mt={10}>
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {/* {success && (
        <Alert status="success" mt={10}>
          <AlertIcon />
          <AlertTitle>{successMsg}</AlertTitle>
        </Alert>
      )} */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getTagSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Flex direction="column" rowGap={4} as={Form}>
            <FormControl
              mt={"50px"}
              isInvalid={errors.reference && touched.reference}
            >
              <FormLabel>Reference Number</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="reference"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.reference}
              />
              {touched.reference && errors.reference && (
                <FormErrorMessage>{errors.reference}</FormErrorMessage>
              )}
            </FormControl>
            <Text fontSize={14} color={"#494949"} fontWeight={600}>
              Kindly check your email for required Reference Number!
            </Text>
            {!retryPaymentUrl ? (
              <Button
                mt={6}
                width={"100%"}
                background={"#22944E"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Loading..."
              >
                Get Tag
              </Button>
            ) : (
              <Button
                mt={8}
                width={"100%"}
                background={"#22944E"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                onClick={handleRetryPayment}
              >
                Retry Payment
              </Button>
            )}
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default GetTag;
