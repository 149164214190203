import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import CreateAndFilter from "../components/layout/CreateAndFilter";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchspeakers } from "../redux/slices/guestSpeakerSlice";
import {
  setEditSpeaker,
  setSpeakerDetails,
} from "../redux/slices/guestSpeakerSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";

const EventSpeaker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchspeakers());
    dispatch(clearsearchFilter());
  }, [dispatch]);

  const allSpeakers = useSelector((state) => state.guestSpeaker?.speakers);

  const createData = {
    createText: "Add Speaker",
    onClick: () => {
      dispatch(
        setFormModal({
          status: true,
          title: "Add Event Speaker",
          type: "addSpeaker",
        })
      );
    },
  };

  const handleUpdate = async (item) => {
    // console.log("item",item);
    await dispatch(setSpeakerDetails(item));
    dispatch(
      setFormModal({
        status: true,
        title: " Guest Speaker Details",
        type: "speakerDetails",
      })
    );
  };

  const handleEdit = async (item) => {
    // console.log("item",item);
    await dispatch(setEditSpeaker(item));
    dispatch(
      setFormModal({ status: true, title: "Edit Speaker", type: "editSpeaker" })
    );
  };

  const handleDelete = async (item) => {
    // console.log("Delete item",item);

    try {
      await httpRequest(
        `${BASE_URL}/GuestSpeaker/delete-speaker/${item.id}`,
        "post"
      );

      await dispatch(fetchspeakers());
    } catch (error) {
      console.log({ error });
    }
  };

  const tableProps = {
    data: allSpeakers,
    title: {
      speaker: "Keynote Speaker",
      topic: "Topic",
      event: "Event Name",
      Status: "Status",
      Action: "Action",
    },
    dataKeys: ["speaker", "topic", "event", "Status", "Action"],
    variant: "true",
    transformRow: (item) => ({
      speaker: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.firstName + " " + item?.lastName}
        </div>
      ),
      topic: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.topic}
        </div>
      ),
      event: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.event?.name}
        </div>
      ),
      Status: (
        <Button
          bg={""}
          borderRadius={"4px"}
          fontSize={"16px"}
          lineHeight={"20px"}
          _hover={{ backgroundColor: "" }}
          onClick={() => handleUpdate(item)}
          color={item?.isActive ? "#356EFF" : "#FFC34F"}
        >
          {item?.isActive ? "Active " : "Inactive"}
        </Button>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>
              {/* {item?.isActive ? "Deactivate" : "Activate"} */}
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <>
      <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"}>
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Event Speaker
          </Text>
          <CreateAndFilter {...createData} />
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default EventSpeaker;
