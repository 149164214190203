import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import eventImg1 from "../Assets/Images/event-img1.svg";
import {
  Box,
  Flex,
  Text,
  // InputGroup, InputRightElement, Input, Select,
  Grid,
  GridItem,
  Image,
  Button,
} from "@chakra-ui/react";
// import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Header from "../components/layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEvents } from "../redux/slices/eventRegistrationSlice";
import ncsBg from "../Assets/Images/ncs-event-bg-img.jpg";
import MobileHeader from "../components/layout/MobileHeader";

const AllEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllEvents());
  }, [dispatch]);

  const allEvents = useSelector((state) => state.eventRegistration?.allEvents);
  console.log("allEvents", allEvents);

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(allEvents?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEvents = allEvents.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  return (
    <>
      <Header logo={"true"} />
      <MobileHeader show={false} logo={"true"} />
      <Box backgroundImage={ncsBg} color={"#F0F0F0"}>
        <Box bg={"rgba(4,9,30,0.8)"} py={"30px"} minH={"100vh"}>
          <Flex
            flexDirection={["column", "row", "row"]}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"20px"}
            borderBottom={"1px solid #B4B1B1"}
            py={"20px"}
            px={"20px"}
            mb={5}
          >
            <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
              All Events
            </Text>
            {/* <Flex gap={3} flexDirection={['column', 'column', 'row']} alignItems={'center'} ml={'60px'}>
                    <InputGroup >
                        <InputRightElement pointerEvents="none">
                        <BiSearch color="gray" />
                        </InputRightElement>
                        <Input placeholder="Search" />
                    </InputGroup>

                    <Select bg={'brand.primary'}  focusBorderColor="0.5px solid brand.primary" _hover={{border: ""}} border={'1px solid #22944E'}
                    _placeholder={{ color: "#8A8C8E" }} color={'#FFFFFF'} borderRadius={'0'} 
                    >
                        <option value='Filter by Ref Code' selected  style={{backgroundColor: "#22944E"}} 
                        >Filter by Code</option>
                        <option value='Filter by Name' style={{backgroundColor: "#22944E"}} >Filter by Name</option>
                        <option value='Filter by Status' style={{backgroundColor: "#22944E"}}>Filter by Status</option>
                    </Select>
                </Flex> */}
          </Flex>

          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={5}
            px={"20px"}
            py={5}
          >
            {currentEvents.toReversed().map((event) => (
              <GridItem key={event?.id}>
                <Flex
                  flexDirection={"column"}
                  lineHeight={"normal"}
                  cursor={"pointer"}
                  onClick={() => navigate(`/event-details/${event?.id}`)}
                >
                  <Box
                    overflow={"hidden"}
                    borderRadius={"16px"}
                    width={["full", null, null, null]}
                    border={"2px solid #FFFFFF"}
                  >
                    <Image
                      src={event?.coverImage ? event?.coverImage : eventImg1}
                      alt="Event Image"
                      width={["full", null, null, null]}
                      height={"300px"}
                    />
                  </Box>
                  <Text fontSize={"18px"} fontWeight={"500"} py={2}>
                    {event?.name}
                  </Text>
                  <Text fontSize={"18px"} fontWeight={"400"}>
                    {event?.location}
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"400"} py={1}>
                    {" "}
                    {format(
                      new Date(event?.startDate),
                      "EEEE do MMM yyyy"
                    )} - {format(new Date(event?.endDate), "EEEE do MMM yyyy")}
                  </Text>

                  <Text fontSize={"18px"} fontWeight={"400"}>
                    {event?.earlyBirdAmount &&
                      `Early Bird: ${formatAmount(event?.earlyBirdAmount)}`}
                  </Text>
                  <Text fontSize={"18px"} fontWeight={"400"}>
                    {event?.lateBirdAmount &&
                      `Late Registration: ${formatAmount(
                        event?.lateBirdAmount
                      )}`}
                  </Text>
                </Flex>
              </GridItem>
            ))}
          </Grid>

          <Flex justifyContent="center" mt={12} mb={5}>
            <Button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              bg={"#F7F9FB"}
              color={"#121212"}
              fontSize={"16px"}
              fontWeight={"400"}
              _hover={{ bg: "#F7F9FB", color: "#121212" }}
            >
              PREV
            </Button>
            {[...Array(totalPages)].map((_, index) => (
              <Button
                key={index}
                mx={2}
                onClick={() => handlePageChange(index + 1)}
                isActive={currentPage === index + 1}
                style={{
                  color: currentPage === index + 1 ? "#FFFFFF" : "#121212",
                  backgroundColor:
                    currentPage === index + 1 ? "#22944E" : "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                _hover={{ bg: "", color: "" }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              bg={"#F7F9FB"}
              color={"#121212"}
              fontSize={"16px"}
              fontWeight={"400"}
              _hover={{ bg: "#F7F9FB", color: "#121212" }}
            >
              NEXT
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default AllEvents;
