import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import CreateAndFilter from "../components/layout/CreateAndFilter";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal, setConfirmModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { sethotelDetails, seteditHotel } from "../redux/slices/hotelAccomSlice";
import { fetchAllHotels } from "../redux/slices/hotelAccomSlice";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";

const HotelAccommodation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllHotels());
    dispatch(clearsearchFilter());
  }, [dispatch]);

  const hotels = useSelector((state) => state.hotel?.hotels);

  const createData = {
    createText: "Add Hotel",
    onClick: () => {
      dispatch(
        setFormModal({ status: true, title: "Add Hotel", type: "addHotel" })
      );
    },
  };

  const handleUpdate = async (item) => {
    // console.log("item",item);
    dispatch(
      setFormModal({
        status: true,
        title: "Hotel Details",
        type: "hotelDetails",
      })
    );
    await dispatch(sethotelDetails(item));
  };

  const handleEdit = async (item) => {
    // console.log("item",item);
    dispatch(
      setFormModal({ status: true, title: "Edit Hotel", type: "editHotel" })
    );
    await dispatch(seteditHotel(item));
  };

  const handleDeactivate = async (item) => {
    // console.log("item",item);
    dispatch(
      setConfirmModal({
        status: true,
        type: "deactivateHotel",
        title: item.isActive ? "Deactivate " : "Activate",
        desc: item.isActive
          ? `Are you sure you want to deactivate ${item.hotelName}?`
          : `Are you sure you want to activate ${item.hotelName}?`,
        buttonText: item.isActive
          ? `Yes, Deactivate ${item.hotelName}`
          : `Yes, Activate ${item.hotelName}`,
        payload: item,
      })
    );
  };

  const handleDelete = async (item) => {
    // console.log("Delete item",item);

    try {
      await httpRequest(
        `${BASE_URL}/Hotel/delete-hotel?hotelId=${item.id}`,
        "post"
      );

      await dispatch(fetchAllHotels());
    } catch (error) {
      console.log(error);
    }
  };

  const tableProps = {
    data: hotels,
    title: {
      hotelName: "Hotel Name",
      location: "Location",
      contact: "Contact Information",
      roomType: "Room Type",
      Status: "Status",
      Action: "Action",
    },
    dataKeys: [
      "hotelName",
      "location",
      "contact",
      "roomType",
      "Status",
      "Action",
    ],
    variant: "true",
    transformRow: (item) => ({
      hotelName: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.hotelName}
        </div>
      ),
      location: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.hotelAddress}
        </div>
      ),
      contact: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.contact}
        </div>
      ),
      roomType: (
        <div style={{ cursor: "pointer" }} onClick={() => handleUpdate(item)}>
          {item?.roomType}
        </div>
      ),
      Status: (
        <Button
          bg={""}
          borderRadius={"4px"}
          fontSize={"16px"}
          lineHeight={"20px"}
          _hover={{ backgroundColor: "" }}
          onClick={() => handleUpdate(item)}
          color={item?.isActive ? "#356EFF" : "#FFC34F"}
        >
          {item?.isActive ? "Active " : "Inactive"}
        </Button>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDeactivate(item)}>
              {item?.isActive ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <>
      <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"}>
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Hotels
          </Text>
          <CreateAndFilter {...createData} />
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default HotelAccommodation;
