import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  // Select,
} from "@chakra-ui/react";
import CustomTable from "../components/CustomTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setEventAttendance,
  addCurrentEvent,
  editEvent,
  fetchAllEvents,
  selectEvents,
} from "../redux/slices/eventSlice";
import { PiPlusBold } from "react-icons/pi";
import httpRequest from "../utils/httpRequest";
import { BASE_URL } from "../config/settings";
import SearchAndFilter from "../components/SearchAndFilter";
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";
import { setloadTost } from "../redux/slices/toastSlice";

const EventManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllEvents());
    dispatch(clearsearchFilter());
  }, [isLoading, dispatch]);

  const events = useSelector(selectEvents);

  const handleAdd = () => {
    dispatch(
      setFormModal({
        status: true,
        title: "Add New Event",
        type: "addEvent",
      })
    );
  };
  const handleBulkUpload = () => {
    dispatch(
      setFormModal({
        status: true,
        title: "Upload Guests",
        type: "uploadGuests",
      })
    );
  };

  const showDetails = async (item) => {
    dispatch(addCurrentEvent(item));
    try {
      const response = await httpRequest(
        `${BASE_URL}/EventManagement/get-attendance?EventId=${item?.id}`
      );
      // console.log(response);
      if (response?.isSuccessful === true) {
        dispatch(setEventAttendance(response.data));
        dispatch(
          setFormModal({
            status: true,
            type: "eventDetails",
            title: "Event Details",
          })
        );
      } else {
        dispatch(
          setloadTost({
            title: "Request Failed",
            description: "Can not view event please Try again later",
            status: "warning",
            duration: 3000,
            isClosable: true,
            position: "top",
          })
        );
      }
    } catch (error) {}
  };

  const handleEdit = async (item) => {
    dispatch(addCurrentEvent(item));
    dispatch(
      setFormModal({ status: true, type: "editEvent", title: "Edit Event" })
    );
  };

  const handleDelete = async (item) => {
    setIsLoading(false);
    try {
      await httpRequest(
        `${BASE_URL}/EventManagement/delete-event/${item.id}`,
        "post"
      );
      setIsLoading(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleActivate = async (item) => {
    setIsLoading(true);
    try {
      if (item.isActive) {
        await httpRequest(
          `${BASE_URL}/EventManagement/deactivate-event/${item.id}`,
          "post"
        );

        // item.isActive = false;
        editEvent(item);
      } else {
        await httpRequest(
          `${BASE_URL}/EventManagement/activate-event/${item.id}`,
          "post"
        );
        // item.isActive = true;
        editEvent(item);
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  const tableProps = {
    data: events,
    title: {
      name: "Event Name",
      startDate: "Start Date",
      endDate: "End Date",
      location: "Event Location",
      isActive: "Status",
      Action: "Action",
    },
    dataKeys: [
      "name",
      "startDate",
      "endDate",
      "location",
      "isActive",
      "Action",
    ],
    variant: "true",
    transformRow: (item) => ({
      eventName: (
        <div style={{ cursor: "pointer" }} onClick={() => showDetails(item)}>
          {item?.name}
        </div>
      ),
      startDate: (
        <div style={{ cursor: "pointer" }} onClick={() => showDetails(item)}>
          {new Date(item?.startDate).toISOString().split("T")[0]}
        </div>
      ),
      endDate: (
        <div style={{ cursor: "pointer" }} onClick={() => showDetails(item)}>
          {new Date(item?.endDate).toISOString().split("T")[0]}
        </div>
      ),
      location: (
        <div style={{ cursor: "pointer" }} onClick={() => showDetails(item)}>
          {item?.location}
        </div>
      ),
      Status: (
        <Button
          bg={""}
          borderRadius={"4px"}
          fontSize={"16px"}
          lineHeight={"20px"}
          _hover={{ backgroundColor: "" }}
          onClick={() => showDetails(item)}
          color={item?.isActive ? "#356EFF" : "#FFC34F"}
        >
          {item?.isActive ? "Active " : "Inactive"}
        </Button>
      ),
      Action: (
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleActivate(item)}>
              {item?.isActive ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <>
      <Box bg={"#FFFFFF"} py={"30px"} minH={"100vh"}>
        <Flex
          flexDirection={["column", "row", "row"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"20px"}
          borderBottom={"1px solid #B4B1B1"}
          py={"20px"}
          px={"20px"}
          mb={5}
        >
          <Text fontSize={"18px"} fontWeight={"400"} fontFamily={"Roboto"}>
            Event Management
          </Text>
          <Flex
            alignItems={"center"}
            gap={5}
            flexDirection={["column", "row", "row"]}
          >
            <Button
              borderRadius={"3px"}
              bg={"brand.primary"}
              leftIcon={<PiPlusBold />}
              color="white"
              onClick={handleAdd}
              px={16}
              _hover={{ backgroundColor: "" }}
            >
              Add New Event
            </Button>
            <Button
              borderRadius={"3px"}
              bg={"brand.primary"}
              leftIcon={<PiPlusBold />}
              color="white"
              onClick={handleBulkUpload}
              px={8}
              _hover={{ backgroundColor: "" }}
            >
              Bulk Upload Guests
            </Button>
            {/* <Select
              bg={"#FFFFFF"}
              focusBorderColor="0.5px solid brand.primary"
              _hover={{ border: "" }}
              border={"1px solid #22944E"}
              _placeholder={{ color: "#8A8C8E" }}
              color={"brand.primary"}
              borderRadius={"0"}
            >
              <option value="Filter by Ref Code">Filter by Code</option>
              <option value="Filter by Name">Filter by Name</option>
              <option value="Filter by Status">Filter by Status</option>
            </Select> */}
            <SearchAndFilter />
          </Flex>
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
};

export default EventManagement;
